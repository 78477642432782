// TODO: Replace with MUI SR utils after v5+ upgrade: https://mui.com/system/screen-readers/
export var visuallyHidden = {
  '&:not(:focus):not(:active)': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1
  }
};